.App {
  text-align: center;
  font-family: 'PT Serif';
}

.App-header {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url('static/images/jiaomin1.jpg');
  background-color: #ffffff;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #1a1a1a;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.square-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  display: inline-flex;
  flex-direction: row;
  margin: 8px;
}

.images {
  margin-top: 40px;
  margin: 40px auto 0 auto;
  max-width: 1400px;
}

.contact {
  height: 50vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 480px) {
  .square-image {
    width: 400px;
    height: 400px;
  }
}

@media (min-width: 320px) {
  .square-image {
    width: 320px;
    height: 320px;
  }
}
